export class Constants {
  // Local host
  // public static CASA_BASE_URL = 'http://localhost:62923/api/';

    /*Stage*/
    public static ENVIRONMENT = 'stage';
    public static CASA_BASE_URL = 'https://saledesk.sconexit.com/Api/';
    // public static CASA_BASE_URL = 'http://stage.casaerp.in/Api/';
    public static CASA_FILE_DOWNLOAD_URL = 'https://stage.casaerp.in/AppUploads/StockInwards/';

  /*Stage*/
  // public static ENVIRONMENT = 'stage';
  // public static CASA_BASE_URL = 'https://stage.casaerp.in/Api/';
  // // public static CASA_BASE_URL = 'http://stage.casaerp.in/Api/';
  // public static CASA_FILE_DOWNLOAD_URL = 'https://stage.casaerp.in/AppUploads/StockInwards/';

  /*Production*/
  // public static ENVIRONMENT = 'production';
  // public static CASA_BASE_URL = 'https://casaerp.in/Api/';
  // // public static CASA_BASE_URL = 'http://casaerp.in/Api/';
  // public static CASA_FILE_DOWNLOAD_URL = 'https://casaerp.in/AppUploads/StockInwards/';


  public static ECOMMERCE_BASE_URL = 'https://casagroup.in/wp-json/';


  // public static CASA_BASE_URL = 'http://casaerpap.shreeyogi.com/Api/';
  // public static CASA_BASE_URL = 'http://casaerpstageapi.shreeyogi.com/Api/';
  // public static CASA_BASE_URL = 'http://hrmsapi.shreeyogi.com/Api/';

  // public static CASA_BASE_URL = 'http://qa.casaerp.in/Api/';
  // public static CASA_BASE_URL = 'http://sconex.casaerp.in/Api/';
  // public static CASA_BASE_URL = 'http://ap.casaerp.in/Api/';

  // Production BackUp
  // public static CASA_BASE_URL = 'http://casaerptn.shreeyogi.com/Api/';
  // public static CASA_BASE_URL = 'http://casaerpap.shreeyogi.com/Api/';


  //Masters
  //GST Masters
  public static GST_POST_SAVE = 'Masters/PostGSTMaster';
  public static GST_GET_GST = 'Masters/GetGSTList';

  public static LOCATION_POST_SAVE = 'Masters/PostLocationMaster';
  public static LOCATION_GET_LOCATIONS = 'Masters/GetLocationList';

  //Sales Target Master
  public static SALESTARGET_POST_SAVE = 'Masters/PostSalesTarget';
  public static SALESTARGET_GET_SALESTARGETS = 'Masters/GetSalesTargets';

  //Incentive Calculation Master
  public static INCENTIVE_POST_GET = 'SalesReport/CalculateSalesIncentive';
  public static INDIVIDUAL_INCENTIVE_POST_GET = 'SalesReport/GetIncentiveReportPerEmployee';
  public static POST_INCENTIVE = 'Masters/PostIncentive';
  public static GET_INCENTIVE_DAILY_TRACKER = 'SalesReport/GetSalesIncentiveDailyTracker';

  // Role Masters
  public static ROLE_GET_ROLES = 'Masters/GetRoleList';
  public static ROLE_GET_ASSIGNED_ROLES = 'Masters/GetRoleAssignmentList';
  public static ROLE_POST_ROLES = 'Masters/PostRoleAssignments';

  public static EMPLOYEE_GET_EMPLOYEES = 'Masters/GetEmployeeList';
  public static EMPLOYEE_POST_SAVE = 'Masters/PostEmployee';

  //Brand Masters
  public static BRAND_POST_SAVE = 'Masters/PostBrandMaster';
  public static POST_UPDATE_BRAND_OFFER = 'Masters/UpdateOffer';
  public static BRAND_GET_BRANDS = 'Masters/GetBrandList';

  public static GET_OFFER_GROUPS = 'Masters/GetOfferGroupList';
  public static GET_OFFER_BRANDS = 'Masters/GetOfferbBrandList';

  public static BRAND_GET_OFFER_ITEMS = 'Masters/GetOfferItems';
  public static BRAND_GET_BRANDS_PER_GROUP = 'Masters/GetBrandListPerGroup';

  //Zone Masters
  public static ZONE_POST_SAVE = 'Masters/PostZoneMaster';
  public static ZONE_GET_BRANDS = 'Masters/GetZones/';

  //Module Mapping
  public static MODULE_GET_GETMODULES = "Masters/GetModules/";
  public static MODULE_POST_GETEMPLOYEESFORMODULE = "Masters/GetEmployeesForModule";
  public static MODULE_POST_POSTMODULEASSIGNMENT = "Masters/PostModuleAssignments";

  //Temperarure and Body Colors
  public static COLOR_TEMPERATURE_POST_SAVE = 'Masters/PostTemperatureColorMaster';
  public static COLOR_BODY_POST_SAVE = 'Masters/PostBodyColorMaster';
  public static COLOR_BODY_GET_LIST = 'Masters/GetBodyColorList';
  public static COLOR_BODY_GET_LIST_PER_MODEL = 'Masters/GetBodyColorListPerModel';
  public static COLOR_TEMPERATURE_GET_LIST = 'Masters/GetTemperatureColorList';
  public static COLOR_TEMPERATURE_GET_LIST_PER_MODEL = 'Masters/GetTemperatureColorListPerModel';

  //Models
  public static MODEL_GET_MODELS = 'Masters/GetStockModelList';
  public static MODEL_GET_MODELS_PER_BRAND = 'Masters/GetStockModelListPerBrand';
  public static MODEL_POST_SAVE = 'Masters/PostStockModel';

  //Groups
  public static GROUP_GET_GROUPS = 'Masters/GetGroupList';// HAVE TO PASS CATEGORY ID
  public static GROUP_GET_GROUPS_PER_CATEGORY = 'Masters/GetGroupListPerCategory';// HAVE TO PASS CATEGORY ID
  public static GROUP_POST_SAVE = 'Masters/PostGroupMaster';// HAVE TO PASS CATEGORY ID


  // Stock Outward Module
  // Repair/Replacement
  public static SO_GET_PURCHASE_INVOICES_PER_SUPPLIER = 'StockOutward/GetPurchaseInvoicesPerSupplier';
  public static SO_GET_ITEMS_PER_INVOICE = 'StockOutward/GetItemsPerInvoice';
  public static SO_POST_SENT_TO_REPAIR = 'StockOutward/PostSentToRepair';
  public static SO_GET_SENT_TO_REPAIRS = 'SalesReport/GetSentToRepairs';
  public static SO_GET_SENT_TO_REPAIR = 'StockOutward/GetSentToRepair';

  //Categories
  public static CATEGORY_GET_CATEGORIES = 'Masters/GetStockCategoryList';

  // Purchase Orders
  public static PO_GET_OPEN_REQUISTIONS = 'PurchaseOrder/GetOpenRequistions';
  public static PO_GET_OPEN_REQUISTION_ITEMS = 'PurchaseOrder/GetOpenRequistionItems';
  public static PO_POST_PURCHASE_ORDER = 'PurchaseOrder/PostPurchaseOrder';
  public static PO_GET_OPEN_PURCHASE_ORDER_LIST = 'PurchaseOrder/GetOpenPurchaseOrderList';
  public static PO_GET_PURCHASE_ORDERS = 'PurchaseOrder/GetOpenPurchaseOrders';
  public static PO_GET_PURCHASE_ORDER = 'PurchaseOrder/GetPurchaseOrder';

  public static PO_GET_BARCODES = 'PurchaseOrder/GetBarcodesList';

  //Stock Request
  public static SR_GET_OPEN_REQUISITIONS = 'PurchaseOrder/GetOpenRequistions';
  public static SR_GET_OPEN_REQUISITION_ITEMS = 'PurchaseOrder/GetOpenRequistionItems';
  public static SR_POST_PURCHASE_ORDERS = 'PurchaseOrder/PostPurchaseOrder';
  public static SR_GET_OPEN_PURCHASE_ORDERS = 'PurchaseOrder/GetOpenPurchaseOrderList';
  public static SR_GET_OPEN_PURCHASE_ORDERS_ITEMS = 'PurchaseOrder/GetPurchaseOrder';
  // public SR_POST_PURCHASE_ORDERS ='PurchaseOrder/PostPurchaseOrde';
  public static GET_SUPPLIERS = 'Masters/GetSupplierList';
  // Accounts Module
  // Journal Voucher
  public static MASTERS_ACCOUNT_LIST = 'Masters/GetAccountList';
  public static JV_GET_JOURNAL_VOUCHERS_LIST = 'AccountsEntry/GetJVList';
  public static JV_GET_JOURNAL_VOUCHER = 'AccountsEntry/GetJVItem';

  //Customer ledger
  public static CUSTOMER_GET_LEDGER = "AccountsEntry/GetLedger";

  //Purchase Register
  public static PURCHASE_GET_REGISTER = "PurchaseReports/GetPurchaseRegister";
  public static PURCHASE_GET_REGISTER_PER_ITEM = "PurchaseReports/GetPurchaseRegisterPerItem";
  public static SALERETURN_GET_REGISTER_PER_ITEM = "PurchaseReports/GetSaleReturnRegisterPerItem";

  public static PURCHASE_GET_BRANCH_INWARD_PER_ITEM = "PurchaseReports/GetBranchInwardPerItem";

  //Godown Stocks
  public static GS_GET_SALE_STOCKINWARD = "StockInward/GetSaleStockInward";

  public static GS_GET_STOCKINWARD = "StockInward/GetStockInward";
  public static GS_POST_GODOWNSTOCK = "StockInward/PostGodownStock";
  public static GS_GET_GODOWNSTOCKBARCODES = "StockInward/GetBarCodes";

  //Branch Stock reports
  public static BRANCH_GET_STOCKREQUESTS = "PurchaseOrder/GetStockRequestReport";
  public static BRANCH_GET_STOCKREQUESTS_PER_ITEM = "PurchaseOrder/GetPurchaseOrder";
  public static POST_STOCKREPORTNEW = "StockReports/GetStockClosingBalance";

  //Sale Register reports
  public static SALEREG_POST_GETSALEINVOICE = "SalesReport/GetSaleInvoices";
  public static SALEREG_GET_GETSALEITEMS = "SalesReport/GetSaleItems";
  public static SALEREG_POST_GETITEMSALES = "SalesReport/GetItemSales";
  public static POST_NETSALEPEREXECUTIVE = "SalesReport/GetNetSalesPerEmployee";
  public static POST_GETSALEPEREMPLOYEE = "SalesReport/GetSalesPerEmployeeDetail";

  public static POST_GET_REDEEM_POINTS = "SalesReport/GetRedeemPoints";

  public static POST_GETSALERETURNS = "PurchaseReports/GetSaleReturnRegister";
  //Purcahse Register reports
  public static PURCAHSEREG_GET_GETSALEITEMS = "PurchaseReports/GetPurcahseItems";


  //Branch Reports
  //Branch Outwards
  public static BRANCH_POST_GETBRANCHOUTWARDS = "SalesReport/GetBranchOutwards";
  public static BRANCH_GET_GETBRANCHOUTWARD = "StockOutward/GetBranchOutward";
  public static BRANCH_GET_GETITEMOUTWARDS = "SalesReport/GetItemOutwards";
  // 
  //Branch Inwards
  public static BRANCH_POST_GETBRANCHINWARDREGISTER = "PurchaseReports/GetBranchInwardRegister";
  public static BRANCH_GET_GETSTOCKINWARD = "StockInward/GetStockInward";

  //Branch Inwards
  public static BRANCH_POST_GETPURCHASEORDERREP = "PurchaseOrder/GetPurchaseOrderReport";
  public static BRANCH_GET_GETPURCHASEORDER = "PurchaseOrder/GetPurchaseOrder";

  // Sale Return
  public static CREDIT_NOTE_LIST = 'StockInward/GetSaleReturnList';

  //SentToRepairs GetSentToRepairs
  public static GET_SENT_TO_REPAIRS = 'SalesReport/GetSentToRepairs';
  public static GET_SENT_TO_REPAIRS_DETAILS = 'StockOutward/GetSentToRepairs';

  //Sale Report
  //Group Sales
  public static GET_GROUP_SALES = 'SalesReport/GetGroupSale';
  public static GET_GROUP_SALES_ITEMS = 'SalesReport/GetGroupSaleItems';

  //Accounts Module
  //Masters
  public static ACCOUNTS_MASTERS_GETPARENTGROUPS = "AccountMaster/GetParentGroups";

  public static ACCOUNTS_MASTERS_GETSUBGROUPS = "AccountMaster/GetSubGroups";

  public static ACCOUNTS_MASTERS_GETAllGroups = "AccountMaster/GetAllGroups";
  public static ACCOUNTS_MASTERS_GETAllEDITABLEGROUPS = "AccountMaster/GetEditableGroups";
  public static ACCOUNTS_MASTERS_POSTGROUP = "AccountMaster/PostAccountGroup";
  public static ACCOUNTS_MASTERS_GETCASHBANKACCOUNTS = "AccountMaster/GetCashBankAccounts";
  public static ACCOUNTS_MASTERS_GETALLACCOUNTS = "AccountMaster/GetAccounts";
  public static ACCOUNTS_MASTERS_GETPAYMENTACCOUNTS = "AccountMaster/GetPaymentAccounts";
  public static ACCOUNTS_MASTERS_POSTACCOUNTLEDGER = "AccountMaster/PostAccountLedger";
  public static ACCOUNTS_MASTERS_GETNONBANKACCOUNTS = "AccountMaster/GetNonBankAccounts";
  public static ACCOUNTS_MASTERS_GETBANKACCOUNTS = "AccountMaster/GetBankAccounts";
  public static ACCOUNTS_MASTERS_GETCONSOLIDATEDBANKACCOUNTS = "AccountMaster/GetConsolidatedBankAccounts";
  public static ACCOUNTS_MASTERS_GETCHEQUEBOOKS = "AccountMaster/GetChequeBooks";
  public static ACCOUNTS_MASTERS_POSTCHEQUEBOOK = "AccountMaster/PostChequeBook";

  public static ACCOUNTS_MASTERS_GETFINANCERS = "AccountMaster/GetFinancersAccounts/";


  public static ACCOUNTS_TRANSACTIONS_POSTUNCLEAREDSALESCHEQUES = "AccountsEntry/PostClearSaleChque";
  public static ACCOUNTS_TRANSACTIONS_GETUNCLEAREDSALESCHEQUES = "AccountsEntry/GetUnclearedSaleCheques/";

  // public static ACCOUNTS_MASTERS_GET_NONBANKACCOUNTS = "AccountMaster/GetNonBankAccounts";

  public static ACCOUNTS_TRANSACTIONS_POST_UNCLEAREDSALESCHEQUES = "AccountsEntry/UpdateBRS";
  public static ACCOUNTS_TRANSACTIONS_GET_UNCLEAREDSALESCHEQUES = "AccountsEntry/GetBankTransactions";

  //Journal Voucher
  public static ACCOUNTS_JOURNALVOUCHER_POSTVOUCHERENTRY = "AccountsEntry/PostVoucherEntry";
  public static ACCOUNTS_JOURNALVOUCHER_GETPAYMENTVOUCHERS = "AccountsEntry/GetVoucherEntryList";
  public static ACCOUNTS_JOURNALVOUCHER_GETPAYMENTVOUCHER = "AccountsEntry/GetJVItem";

  //Reports
  public static ACCOUNTS_REPORTS_GET_SALES_RETURN_TAX_WISE = "GSTReports/GetSaleReturn";
  public static ACCOUNTS_REPORTS_GET_PURCHASE_RETURN_TAX_WISE = "GSTReports/GetPurchaseReturn";
  public static ACCOUNTS_REPORTS_GET_PROFIT_AND_LOSS = "AccountsEntry/GetProfitAndLoss";
  public static ACCOUNTS_REPORTS_GET_TRAIL_BALANCE = "AccountsEntry/GetTrailBalance";
  public static ACCOUNTS_REPORTS_GetNonActiveVouchers = "AccountsEntry/GetNonActiveVouchers/";
  public static ACCOUNTS_REPORTS_PURCHASEVSSALE_BALANCE = "GSTReports/GetPurchasesVsSalesBalance";
  public static ACCOUNTS_REPORTS_PURCHASERETURNITEMIZED = "GSTReports/GetPurchaseReturnItemized";
  public static ACCOUNTS_REPORTS_GETLEDGER = "AccountsEntry/GetLedger";
  public static ACCOUNTS_REPORTS_CONSOLIDATED_BANK_BOOK = "AccountsEntry/GetConsolidatedLedger";
  public static ACCOUNTS_REPORTS_GETDAYSHEET = "AccountsEntry/GetDaySheet";
  public static ACCOUNTS_REPORTS_UPDATE_DAYSHEET = 'AccountsEntry/UpdateDaySheet';
  public static ACCOUNTS_REPORTS_PRINTDAYSHEET = "AccountsEntry/PrintDaySheet";
  public static ACCOUNTS_REPORTS_PRINTLEDGER = "AccountsEntry/PrintLedger";
  public static ACCOUNTS_REPORTS_GETGROUPLEDGER = 'AccountsEntry/GetGroupLedger';
  public static ACCOUNTS_REPORTS_GETACCOUNTRECEIVABLES = "AccountsEntry/GetAccountReceivables";
  public static ACCOUNTS_REPORTS_GETACCOUNTRECEIVABLEAGING = "AccountsEntry/GetAccountReceivableAging";

  public static ACCOUNTS_REPORTS_POSTACCOUNTSHOWORHIDE = "AccountMaster/PostAccountShowOrHide";

  public static ACCOUNTS_REPORTS_GETACCOUNTPAYABLES = "AccountsEntry/GetAccountPayables";
  public static ACCOUNTS_REPORTS_GETACCOUNTPAYABLEAGING = "AccountsEntry/GetAccountPayableAging";
  public static ACCOUNTS_REPORTS_GETSALESITEMIZED = "GSTReports/GetSalesItemized";
  public static ACCOUNTS_REPORTS_GETSALESTAXWISE = "GSTReports/GetSalestaxwise";
  public static ACCOUNTS_REPORTS_GETPURCHASEITEMIZED = "GSTReports/GetpurchaseItemized";
  public static ACCOUNTS_REPORTS_GETPURCHASETAXWISE = "GSTReports/GetPurchaseTaxWise";
  public static ACCOUNTS_REPORTS_GETPURCHASEVSSALES = "GSTReports/GetPurchasesVsSales";
  public static ACCOUNTS_REPORTS_GETSALERETURNITEMIZED = "GSTReports/GetSaleReturnItemized"

  public static ACCOUNTS_REPORTS_PURCHASEVSSALE_BALANCE_CONSOLIDATED = "GSTReports/GetPurchasesVsSalesBalanceConsolidated";
  public static ACCOUNTS_REPORTS_PURCHASERETURNITEMIZED_CONSOLIDATED = "GSTReports/GetPurchaseReturnItemizedConsolidated";
  public static ACCOUNTS_REPORTS_GETSALESITEMIZED_CONSOLIDATED = "GSTReports/GetSalesItemizedConsolidated";
  public static ACCOUNTS_REPORTS_GETSALESTAXWISE_CONSOLIDATED = "GSTReports/GetSalestaxwiseConsolidated";
  public static ACCOUNTS_REPORTS_GETPURCHASEITEMIZED_CONSOLIDATED = "GSTReports/GetpurchaseItemizedConsolidated";
  public static ACCOUNTS_REPORTS_GETPURCHASETAXWISE_CONSOLIDATED = "GSTReports/GetPurchaseTaxWiseConsolidated";
  public static ACCOUNTS_REPORTS_GETPURCHASEVSSALES_CONSOLIDATED = "GSTReports/GetPurchasesVsSalesConsolidated";
  public static ACCOUNTS_REPORTS_GETSALERETURNITEMIZED_CONSOLIDATED = "GSTReports/GetSaleReturnItemizedConsolidated"
  public static ACCOUNTS_REPORTS_AGING_RECEIVABLES = "AccountsEntry/GetPaymentAging/"
  //Accounts Dasahboard
  public static ACCOUNTS_DASHBOARD_AGING_RECEIVABLES = "AccountsEntry/GetPaymentAgingGraph/"

  //Branch Inwards
  public static REPRINTS_GET_GETSALEINVOICE = "StockOutward/PrintSaleInvoice";
  public static REPRINTS_GET_GETOUTWARD = "StockOutward/PrintBranchOutward";

  //hrms module
  //employee
  public static EMPLOYEE_GET_GETREPORTINGMANAGERS = 'Masters/GetReportingManagers/';
  public static EMPLOYEE_GET_GETEMPLOYEES = 'Masters/GetEmployeeList/';
  public static EMPLOYEE_POST_POSTEMPLOYEE = 'Masters/PostEmployee';

  //empoyee Personal Information
  public static EMPLOYEE_GET_PERSONALINFORMATION = 'Hrms/GetEmployeeInfo/';
  public static EMPLOYEE_POST_PERSONALINFORMATION = 'Hrms/PostEmployeeInfo';

  //emplyee Bank Information
  public static EMPLOYEE_GET_BANKDETAILS = 'Hrms/GetBankInfo/';
  public static Employee_POST_BANKDETAILS = 'Hrms/PostBankDetails';

  //employee Salary Structure
  public static EMPLOYEE_GET_SALARYSTRUCTURE = 'Hrms/GetSalaryStructure';
  public static EMPLOYEE_POST_SALARYSTRUCTURE = 'Hrms/PostSalaryStrcture';

  //leave master
  public static EMPLOYEE_GET_LEAVEMASTER = 'Hrms/GetLeaveMaster';
  public static EMPLOYEE_POST_LEAVEMASTER = 'Hrms/PostLeaveMaster';

  //for getting salary advance request list
  public static EMPLOYEE_GET_SALARYADVANCEREQUEST = 'Hrms/GetSalaryAdvanceRequests/';
  public static EMPLOYEE_POST_SALARYADVANCEREQUESTAPPROVAL = 'Hrms/PostSalaryAdvanceRequest';

  public static EMPLOYEE_POST_SALARYADVANCEREQUEST = 'Hrms/PostSalaryAdvanceRequest';

  //Employee Pay Slip
  public static EMPLOYEE_GET_PAYSLIP = "Hrms/GetPaySlip";
  public static EMPLOYEE_GET_PAYROLLS = "Hrms/RunPayRoll";

  //Leave Request Report
  public static EMPLOYEE_GET_LEAVEREQUESTS = "Hrms/GetLeaveRequests";
  public static LEAVETYPE_GET_LEAVETYPES = "Hrms/GetLeaveMaster";
  public static LEAVEBALANCE_GET_LEAVEBALANCE = "Hrms/GetLeaves?EmployeeID=";
  public static EMPLOYEE_POST_POSTLEAVEREQUEST = 'Hrms/PostNewLeaveRequest';

  //for updating attendance
  public static EMPLOYEE_GET_GETATTENDANCE = 'Hrms/GetAttendance?date=';
  public static EMPLOYEE_POST_UPDATEATTENDANCE = 'Hrms/PostAttendance';

  //leave request approval
  public static EMPLOYEE_GET_GETLEAVEREQUEST = 'Hrms/GetLeaveRequests/';
  public static EMPLOYEE_POST_APPROVELEAVEREQUEST = 'Hrms/PostNewLeaveRequest';

  //Holiday Calender
  public static GET_HOLIDAY_CALENDER = "Hrms/GetHolidayCalendar";
  public static POST_HOLIDAY_CALENDER = "Hrms/PostHolidayCalendar";
  public static EMPLOYEE_GET_GETDETAILS = 'Hrms/GetEmployees';

  //GetSaleTrend
  public static GET_SALE_TREND = "SalesReport/GetSaleTrendSelectedMonth";
  public static GET_SALE_TREND_ALL_ITEMS_DAYWISE = "SalesReport/GetSaleTrendAllItemsDayWise";

  //Customer Sale Trend
  public static GET_CUSTOMER_SALE_TREND = "SalesReport/GetSaleTrendAllItemsForCustomer";

  //Advance Items
  public static GET_ADVANCE_ITEMS = "SalesReport/GetAdvanceItems/";
  public static POST_ADVANCE_ITEMS = "StockOutward/CloseAdvanceItems";

  //Get customrs list by search
  public static GET_CUSTOMERS_BY_SEARCH = "Masters/GetCustomerList";

  //Product enuiry
  public static GET_PRODUCT_ENQUIRY_CUSTOMER = "Quotation/GetProductEnquiryCustomer";
  public static GET_PRODUCT_ENQUIRY = "Quotation/GetProductEnquiry";
  public static POST_PRODUCT_ENQUIRY_CUSTOMER = "Quotation/PostProductEnquiryCustomer";
  public static GET_PRODUCT_ENQUIRY_HISTORY = "Quotation/GetProductEnquiryHistory";
  public static GET_PRODUCT_ENQUIRY_LIST = "Quotation/GetEnquiryList";

  public static GET_ITEM_QUANTITY_LIST = "Quotation/GetEnquiryItemsQuantity";
  public static GET_ENQUIRY_CUSTOMERS_LIST = "Quotation/GetEnquiryCustomers";
  public static GET_ENQUIRY_SALES_PERSONS_LIST = "Quotation/GetEnquirySalesPersons";

  //Store target
  public static POST_STORE_TARGET = "Masters/PostUpdateTarget";
  public static GET_STORE_TARGET = "Masters/GetBusinessTarget/";

  public static POST_GET_DAILY_STANDUP_REPORT = "SalesReport/GetDailyStandupReport";

  //BalanceSheet
  public static POST_GET_BALANCE_SHEET_REPORT = "AccountsEntry/GetBalanceSheet";
  public static POST_GET_LEDGER_BALANCE_PER_GROUP = "AccountsEntry/GetLedgerBalancesPerGroup";
  public static POST_GET_CONSOLIDATED_BALANCE_SHEET_REPORT = "AccountsEntry/GetConsolidatedBalanceSheet";

  //Consolidated accounts reports
  public static POST_GET_CONSOLIDATED_PROFIT_LOSS = "AccountsEntry/GetConsolidatedProfitAndLoss";
  public static POST_GET_CONSOLIDATED_TRAIL_BALANCE = "AccountsEntry/GetConsolidatedTrailBalance";

  //Manual Updates
  public static POST_MANUAL_UPDATES = "Masters/PostManualUpdate";

  //Service Module
  public static GET_OPEN_SERVICE_REQUESTS = "Service/GetOpenRequests";
  public static POST_SERVICE_REQUESTS = "Service/RequestUpdate";
  public static POST_GET_SERVICE_REQUEST = "Service/GetRequest";
  public static POST_CLOSE_SERVICE_REQUEST = "Service/RequestClose";
  public static POST_GET_CLOSED_SERVICE_REQUESTS = "Service/GetClosedRequests";
  public static GET_SERVICE_AGENTS = "Service/GetServiceAgents/";
  public static GET_SERVICE_AGENT_EMP = "Service/GetServiceAgentEmployees";
  public static POST_SERVICE_AGENT = "Service/CreateServiceAgent";
  public static POST_SERVICE_AGENT_EMP = "Service/CreateTechnician";
  public static POST_MAP_AGENT_CATEGORY = "Service/MapAgentToCategory";
  public static POST_SERVICE_PRICE_UPDATE = "Service/UpdateServicePrice";
  public static POST_TECHNICIAN_JOB_START = "Service/TechnicianJobStart";

}
